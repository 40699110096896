<script lang="ts" setup></script>

<template>
  <li class="user-sidebar-item"><slot /></li>
</template>

<style lang="scss">
.user-sidebar-item {
  @include t-desktop-link-small;

  a {
    color: var(--c-primary-default);

    &:hover {
      color: var(--c-secondary-default);
    }
  }

  &--active {
    color: var(--c-secondary-default) !important;
  }
}

</style>
