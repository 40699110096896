<script lang="ts" setup>
import Header from '@components/header/index.vue'
import Footer from '@components/footer/index.vue'
import Sidebar from '@components/user/sidebar/index.vue'
import SidebarItem from '@components/user/sidebar/item.vue'

const { t } = useI18n()
const localePath = useLocalePath()
</script>

<template>
  <div class="wrapper">
    <Header />

    <div class="main-container">
      <div class="flex gap-8">
        <Sidebar class="w-3/12">
          <SidebarItem
            ><NuxtLink
              :to="localePath('/user')"
              active-class="user-sidebar-item--active"
              >{{ t('main') }}</NuxtLink
            ></SidebarItem
          >
          <SidebarItem
            ><NuxtLink
              :to="localePath('/user/setting')"
              active-class="user-sidebar-item--active"
              >{{ t('account') }}</NuxtLink
            ></SidebarItem
          >
          <SidebarItem
            ><NuxtLink
              :to="localePath('/')"
              active-class="user-sidebar-item--active"
              >{{ t('api') }}</NuxtLink
            ></SidebarItem
          >
          <SidebarItem
            ><NuxtLink
              :to="localePath('/user/payments')"
              active-class="user-sidebar-item--active"
              >{{ t('payments') }}</NuxtLink
            ></SidebarItem
          >
        </Sidebar>

        <div class="w-9/12"><slot /></div>
      </div>
    </div>

    <Footer />
  </div>
</template>
