<script lang="ts" setup>
const { t } = useI18n()
const localePath = useLocalePath()
</script>

<template>
  <div class="user-sidebar">
    <ul class="user-sidebar-items">
      <slot />
    </ul>

    <div class="user-sidebar-logout">
      <slot name="logout-link">
        <NuxtLink :to="localePath('/')">{{ t('actions.quit') }}</NuxtLink>
      </slot>
    </div>
  </div>
</template>

<style lang="scss">
.user-sidebar {
  &-logout {
    margin-top: 55px;

    a {
      color: var(--c-grayscale-placeholder);

      @include t-desktop-link-small;
    }
  }
}

.user-sidebar-items {
  display: flex;
  flex-direction: column;

  list-style: none;

  gap: 16px;
}
</style>
